import { internalGetCookie } from "./cookies";

export const getMobileOperatingSystem = () => {
    const userAgent = (navigator.userAgent || navigator.vendor || window.opera).toLowerCase();
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/windows/i.test(userAgent)) {
        return "Windows";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/linux/i.test(userAgent)) {
        return "Linux";
    }

    if (/iphone|ipad|ipod/i.test(userAgent) || navigator.maxTouchPoints > 0) {
        return "iOS";
    }

    if (/macintosh|mac os x/i.test(userAgent)) {
        return "MacOS";
    }

    return "Unknown";
};

export const loginRegistrationAdditionalHeaders = () => {
    let platform = 'web';
    const mobileOperatingSystem = getMobileOperatingSystem().toLowerCase();
    if (mobileOperatingSystem === 'android') {
        platform += '-android';
    } else if (mobileOperatingSystem === 'ios') {
        platform += '-ios';
    }

    return {
        'x-platform': platform
    };
};

export const is_safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

export const AddOrRemoveSpinner = (show = false) => {
    const spinner = document.querySelector('.spinner');
    if (show) {
        spinner?.classList.add('show');
    } else {
        spinner?.classList.remove('show');
    }
}

export const isDeveloperMode = () => {
    return internalGetCookie('developer_mode') === '1';
}